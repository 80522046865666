import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context';
import Footer from '../global-components/footer';
import { useParams } from "react-router-dom";
import PageHeader from '../global-components/page-header';
import ServiceBlock from './ServiceBlock';
import Navbar from '../global-components/navbar';
import Map from '../contact-component/map';
import Values from '../section-components/values';

function ServicesDetail() {
    const { rpdata } = useContext(GlobalDataContext);
    const { id } = useParams();
    console.log(id);

    return (
        <div>
            <Navbar />
            {rpdata?.dbServices?.map((item) => {
                if (item.name.replaceAll(" ", "-").toLowerCase() === id) {
                    return (
                        <div>
                            <PageHeader
                                headertitle={item.name}
                                Subheader={item.name}
                                backgroundImage={item.description[0].img}
                            />
                            <ServiceBlock
                                servicesName={item.name}
                                imgServices={item.description[0].img}
                                description={item.description.map((i, index) => {
                                    return <p key={index}>{i.text}</p>;
                                })}
                                itemServives={item.description.map((it, index) => {
                                    return it.lists[0] !== " "
                                        ? it.lists.map((list, index) => {
                                            return (
                                                <li
                                                    className="single-list-inner style-check-box  list-unstyle"
                                                    key={index}
                                                >
                                                    <i className="fa fa-check text-white" /> {list}
                                                </li>
                                            );
                                        })
                                        : null;
                                })}
                            />
                        </div>
                    );
                }
            })}
            <Values/>
            <Map/>
            <Footer />
        </div>
    )
}

export default ServicesDetail