import React from "react";

const ColorPalette = (props) => {

  return (
    <div>
      <div className="container">
        <div className="row justify-content-center pd-top-50"></div>
        <div className="row justify-content-center pd-top-20">
          <div className="col-lg-3 col-md-6 text-center content-directorios">
            <a href="https://images.sherwin-williams.com/content_images/sw-pdf-sherwin-williams-colorc.pdf"
              target="_blank" rel="noopener noreferrer"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/Directorios%2FSW-1.jpg?alt=media&token=288b0d1c-3606-4e99-98a9-5f3539cd0081"
                alt="Sherwin Williams"
              ></img>
            </a>
          </div>
          <div className="col-lg-3 col-md-6 text-center content-directorios">
            <a href="https://www.benjaminmoore.com/en-us/color-overview/color-palettes" target="_blank"  rel="noopener noreferrer">
              <img src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/Directorios%2Fbjm-1.jpg?alt=media&token=30c85c07-dcc1-4601-81cf-40186bbc014b" alt="benjamin-moore"></img>
            </a>
          </div>
          <div className="col-lg-3 col-md-6 text-center content-directorios">
            <a href="https://www.behr.com/consumer/colors/paint/" target="_blank"  rel="noopener noreferrer">
              <img src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/Directorios%2Fbehr-1.jpg?alt=media&token=5b20facf-8923-4bad-9c02-b2d6d9f5b44e" alt="behr"></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorPalette;
