import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

const Directories = (props) => {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <div>
      <div className="container">
        <div className="row justify-content-center text-center pd-top-40">
          {
            rpdata?.gmb?.link.length > 0 ? (
              <a href={`${rpdata?.gmb?.link}`} target="_blanck" rel="noopener noreferrer">
                <img src={rpdata?.gmb?.img} alt="img" width={'300px'}></img>
              </a>
            ) : null
          }
        </div>
        <h2 className="pd-top-10 text-center">Find Us</h2>
        <div className="row justify-content-center pd-top-20">

          {rpdata?.dbSocialMedia?.directorios.map((item, index) => {
            return (
              <div
                className="col-lg-3 col-md-6 text-center content-directorios"
                key={index}
              >
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  <img src={item.image} alt="img"></img>
                </a>
              </div>
            );
          })}
          {rpdata?.dbSocialMedia?.redes.map((item, index) => {
            return (
              <div
                className="col-lg-3 col-md-6 text-center content-directorios"
                key={index}
              >
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  <img src={item.image} alt="img2"></img>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Directories;