import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { useParams } from "react-router-dom";
import PageHeader from '../global-components/page-header';
import Footer from "../global-components/footer";
import "photoswipe/dist/photoswipe.css";
import Navbar from '../global-components/navbar';
import { Gallery, Item } from "react-photoswipe-gallery";

export const GalleryDetail = () => {
  const { rpdata } = useContext(GlobalDataContext);
  const { id } = useParams();
  console.log(id);

  return (
    <div>
      <Navbar />
      {rpdata?.landings?.map((item) => {
        if (item.name.replaceAll(" ", "-").toLowerCase() === id) {
          return (
            <div>
              <PageHeader
                headertitle={item.name}
                Subheader={item.name}
                backgroundImage={
                  rpdata?.stock?.length > 1
                    ? rpdata?.stock[
                        Math.floor(Math.random() * rpdata?.stock?.length)
                      ]
                    : rpdata?.stock[
                        Math.floor(Math.random() * rpdata?.stock?.length)
                      ]
                }
              />
              <div className="container">
                <h2 className="text-center pt-100">Our Recent Projects</h2>
                <div className="gallery-content">
                  <Gallery>
                    {item.gallery.map((item, index) => {
                      return (
                        <Item
                          original={item}
                          thumbnail={item}
                          width="1024"
                          height="768"
                          padding="10px"
                        >
                          {({ ref, open }) => (
                            <img ref={ref} onClick={open} src={item} alt=""/>
                          )}
                        </Item>
                      );
                    })}
                  </Gallery>
                </div>
              </div>
            </div>
          );
        }
      })}
      <Footer />
    </div>
  );
};