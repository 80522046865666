import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";

const FeaturedCourse = (props) => {
		const { rpdata } = useContext(GlobalDataContext);

    return  <div className="course-area pd-top-110 pd-bottom-90 go-top">
			  <div className="container">
			    <div className="row justify-content-center">
			      <div className="col-lg-6">
			        <div className="section-title text-center">
			          <h2 className="sub-title double-line">Our Services</h2>
			        </div>
			      </div>
			    </div>
			    <div className="row">
					{
						rpdata?.dbServices?.slice(0,6).map((item, index) => {
							return <div className="col-lg-4 col-md-4" key={index}>
							<div className="single-course-inner style-two">
							  {/* <div className="emt-thumb-icon">
								<VscTools className="text-white text-justify2"/>
							  </div> */}
							  	<Link className="arrow-right" to="/services">
								  <div className="thumb">
										<div className="row">
											<div className="col-5 text-right">
											<img src={item.description[0].img} alt="img" className='img-card-services-home'/>
											</div>
											<div className="col-6 text-center">
												<div className="serhom">
													<h4 className="texthomns text-center">{item.name}</h4>
													<h6 className='text-services text-center'>{
													item.description[0].text.substring(0, 70) + '...'
													}</h6>
													
												</div>
												
											</div>
											
										</div>
									</div>
								</Link>
							  {/* <div className="details">
								<div className="emt-course-meta border-0">
								  <div className="row">
								  <Link to="/services">
									<div className="col-10">
									  <h6>{item.name}</h6>
									  <h6 className='text-services'>{
									  item.description[0].text.substring(0, 80) + '...'
									  }</h6>
									</div>
									<div className="col-2 text-right">
									  <Link className="arrow-right" to="/services"><i className="fa fa-arrow-right bg-maincolor p-2 rounded-circle text-white"></i></Link>
									</div>
									</Link>
								  </div>
								</div>
							  </div> */}
							</div>
						  </div>
						}
						)
					}

			    </div>
			  </div>

				

			</div>

        }

export default FeaturedCourse